import React, { useEffect, useState } from "react";
import { getDoctors } from "../../utils/doctorUtils/doctorUtils";
import { SelectInput, TextInput } from "../Common/FormComponents";
import { useForm } from "../../hooks";
import { NoDataFound, SearchInput } from "../Common";
import DoctorData from "./DoctorData/DoctorData";
import { toast } from "react-toastify";
import {
  addConsultationAppointment,
  applyOffer,
  verifyOfferOtp,
  // getPurchasedCards,
} from "../../utils/bookConsultation/ConsultationUtils";
import { validateConsultaionForm } from "../../utils/bookConsultation/validateConsultationForm";
import { getDepartments } from "../../utils/departmentUtils/departmentUtils";
import { FaRupeeSign } from "react-icons/fa";
import { useAuth } from "../../context/useAuth";import HaveCard from "./HaveCard";
import { getHospitals } from "../../utils/hospitalUtils/hospitalUtils";
import HospitalData from "./HospitalData/HospitalData";

const ConsultationAppointmentForm = ({
  appointmentDetails = null,
  setDatasFn = null,
  is_follow_up,
  is_re_visit,
  setOpen,
}) => {
  const user = useAuth().authState.user;
  const initialState = {
    patient_id: appointmentDetails?.patient_id?._id,
    disease: is_re_visit ? appointmentDetails?.disease || "" : "",
    payment_status: "Select Status",
    payment_method: "Select Method",
  };
  const [haveCard, setHaveCard] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [selectedDiscount, setSelectedDiscount] = useState(null);
  const [selectedPoints, setSelectedPoints] = useState(null);
  const [deductionRate, setDeductionRate] = useState(null);
  const [reducedPrice, setReducedPrice] = useState(null);

  const [err, setError] = useState({ global: "", offer: "" });
  const [loading, setLoading] = useState(false);
  const [formData, handleInput, clearForm] = useForm(initialState);
  const [doctors, setDoctors] = useState([]);
  const [hospitals, setHospitals] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");

  const [applyReponse, setApplyResponse] = useState(null);
  const [applyOfferLoading, setApplyOfferLoading] = useState(false);

  const [otpResponse, setOtpResponse] = useState(null);
  const [otpLoading, setOtpLoading] = useState(false);
  const [otp, setOtp] = useState("");

  const [offerType, setOfferType] = useState(null);

  const handleOtpVerify = (e) => {
    e.preventDefault();
    setOtpLoading(true);
    verifyOfferOtp({ responseDataId: applyReponse?._id, otpVerify: otp })
      .then((res) => {
        setOtpResponse(res.data);
        if (offerType === "discount") {
          const originalPrice = data?.doctor?.price || 0;
          const newPrice =
            originalPrice - (originalPrice * selectedDiscount) / 100;
          setReducedPrice(newPrice);
        } else if (offerType === "free") {
          setReducedPrice(0);
        } else if (offerType === "points") {
          const originalPrice = data?.doctor?.price || 0;
          const points = selectedPoints || 0;
          const rate = deductionRate || 0;
          const totalDeduction = (points * rate) / 100;
          let finalPrice = originalPrice - totalDeduction;
          setReducedPrice(finalPrice);
        }
        toast("Offer Applyed Successfully", {
          type: "success",
          autoClose: 3000,
        });
      })
      .catch((err) => {
        toast("Offer Applyed Failed", {
          type: "error",
          autoClose: 3000,
        });
      })
      .finally(() => {
        setOtpLoading(false);
      });
  };
  const [selectedHospital, setSelectedHospital] = useState(
    is_re_visit ? appointmentDetails?.hospital_id : null
  );
  const [hospitalSearchQuery, setHospitalSearchQuery] = useState("");
  const [filterdHospitals, setFilteredHospitals] = useState([]);
  useEffect(() => {
    if (!is_re_visit || !selectedHospital) {
      const filteredData = hospitals?.filter((hospital) => {
        const nameMatch = hospital?.hospital_name
          ?.toLowerCase()
          ?.includes(hospitalSearchQuery?.toLowerCase());
        const idMatch = hospital?.hospital_id
          ?.toLowerCase()
          ?.includes(hospitalSearchQuery?.toLowerCase());
        return nameMatch || idMatch;
      });
      setFilteredHospitals(filteredData);
    }
  }, [hospitals, is_re_visit, hospitalSearchQuery, selectedHospital]);
  useEffect(() => {
    if (selectedHospital?._id && !is_re_visit) {
      getDoctors("Approved", selectedHospital?._id)
        .then((res) => {
          setDoctors(res.data?.data);
        })
        .catch((err) => console.log(err));
    }
  }, [is_re_visit, selectedHospital?._id]);
  useEffect(() => {
    if (!is_re_visit) {
      getDepartments()
        .then((res) => {
          setDepartments(res.data.data);
        })
        .catch((err) => console.error(err));
    }
  }, [is_re_visit]);
  useEffect(() => {
    if (!is_re_visit || !selectedHospital) {
      getHospitals()
        .then((res) => {
          setHospitals(res.data.data);
        })
        .catch((err) => console.error(err));
    }
  }, [is_re_visit, selectedHospital, user?._id]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filterdDoctors, setFilteredDoctors] = useState([]);
  const [data, setData] = useState({
    date: null,
    slot: null,
    doctor: is_re_visit ? appointmentDetails?.doctor_id || null : null,
    time: null,
  });
  console.log(data);
  useEffect(() => {
    if (!is_re_visit) {
      const filteredData = doctors?.filter((doctor) => {
        const nameMatch = doctor?.name
          ?.toLowerCase()
          ?.includes(searchQuery.toLowerCase());
        const idMatch = doctor?.doctor_id
          ?.toLowerCase()
          ?.includes(searchQuery?.toLowerCase());
        const departmentMatch = selectedDepartment
          ? selectedDepartment === doctor?.department_id?._id
          : true;
        return (nameMatch || idMatch) && departmentMatch;
      });
      setFilteredDoctors(filteredData);
    }
  }, [doctors, is_re_visit, searchQuery, selectedDepartment]);
  const [userId, setUserId] = useState(null);
  const [cardId, setCardId] = useState(null);
  const handleCardChange = (e) => {
    const checked = e.target.checked;
    setHaveCard(checked);
    if (!checked) {
      setOfferType(null);
      setCardId(null);
      setUserId(null);
      setApplyResponse(null);
      setSelectedOffer(null);
      setSelectedDiscount(null);
      setSelectedPoints(null);
      setDeductionRate(null);
      setReducedPrice(null);
    }
  };
  const handleOfferClick = (
    id,
    userId,
    type,
    index,
    isUsed,
    discount = null,
    points = null,
    rate = null
  ) => {
    if (isUsed) return;
    setSelectedOffer({ type, index });
    if (type === "discount") {
      setUserId(userId);
      setApplyResponse(null);
      setOtpResponse(null);
      setCardId(id);
      setOfferType(type);
      const discountValue = parseFloat(discount);
      // const originalPrice = data?.doctor?.price || 0;
      // const newPrice = originalPrice - (originalPrice * discountValue) / 100;
      // setReducedPrice(newPrice);
      setSelectedDiscount(discountValue);
      setSelectedPoints(null);
      setDeductionRate(null);
    } else if (type === "free") {
      setUserId(userId);
      setApplyResponse(null);
      setOtpResponse(null);
      setCardId(id);
      setOfferType(type);
      // setReducedPrice(0);
      setSelectedDiscount(null);
      setSelectedPoints(null);
      setDeductionRate(null);
    } else if (type === "points") {
      setUserId(userId);
      setApplyResponse(null);
      setOtpResponse(null);
      setCardId(id);
      setOfferType(type);
      // setReducedPrice(0);
      setSelectedDiscount(null);
      setSelectedPoints(points);
      setDeductionRate(rate);
    } else {
      setUserId(null);
      setCardId(null);
      setOfferType(null);
      setApplyResponse(null);
      setOtpResponse(null);
      setSelectedDiscount(null);
      setSelectedPoints(null);
      setDeductionRate(null);
      setReducedPrice(null);
    }
  };
  const handleOfferApply = (e) => {
    e.preventDefault();
    const { doctor } = data;
    setApplyOfferLoading(true);
    applyOffer(doctor, {
      haveCard,
      cardId,
      selectedDiscount,
      selectedPoints,
      offerType,
      userId,
    })
      .then((res) => {
        setApplyResponse(res.data.data);
        toast("Verification for applying offer has been sent successfully.", {
          type: "success",
          autoClose: 3000,
        });
      })
      .catch((err) => {
        toast("Failed to send verification for applying offer.", {
          type: "error",
          autoClose: 3000,
        });
      })
      .finally(() => {
        setApplyOfferLoading(false);
      });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const { doctor, time, slot, date } = data;
    if (validateConsultaionForm(formData, doctor, time, date, setError)) {
      setLoading(true);
      addConsultationAppointment(
        formData,
        selectedHospital?._id,
        doctor,
        time,
        slot,
        date,
        {
          haveCard,
          cardId,
          selectedDiscount,
          doctorReduceCredit: applyReponse?.card_id?.doctor_reduce_credit || 0,
          doctorUsingCredit: applyReponse?.card_id?.doctor_using_credit || 0,
          additionRate: applyReponse?.card_id?.additionRate || 0,
          reducedPrice,
          offerType,
          user_select_offers: applyReponse?._id,
        },
        is_follow_up
      )
        .then((res) => {
          if (setDatasFn) setDatasFn();
          setOpen(false);
          toast(`Consultation booked successfully.`, {
            type: "success",
            autoClose: 3000,
          });
        })
        .catch((err) => {
          console.log(err);
          toast(`Consultation booking failed.`, {
            type: "error",
            autoClose: 3000,
          });
        })
        .finally(() => setLoading(false));
    }
  };
  return (
    <>
      <div className="flex w-full">
        <SearchInput
          placeholder={"Search Hospitals"}
          searchQuery={hospitalSearchQuery}
          setSearchQuery={setHospitalSearchQuery}
        />
      </div>
      {!is_re_visit &&
        (filterdHospitals?.length > 0 ? (
          <div className="w-full min-h-[250px] overflow-y-auto">
            {filterdHospitals?.map((hospital, index) => (
              <HospitalData
                key={index}
                setErr={setError}
                data={
                  hospital?._id === selectedHospital?._id
                    ? selectedHospital
                    : null
                }
                hospital={hospital}
                setData={setSelectedHospital}
              />
            ))}
          </div>
        ) : (
          <div className="text-white">
            <NoDataFound data={"hospitals"} />
          </div>
        ))}
      {is_re_visit && selectedHospital && (
        <div className="w-full min-h-[250px] overflow-y-auto">
          <HospitalData
            hospital={selectedHospital}
            data={selectedHospital}
            setData={setSelectedHospital}
          />
        </div>
      )}
      {!is_re_visit && (
        <div className="flex w-full h-fit justify-between gap-5">
          <div className="lable-and-filed">
            <label htmlFor={"department"} className="label">
              {"Department"}
            </label>
            <div
              className="input-coantiner"
              onClick={() => setError({ global: "" })}
            >
              <select
                className="select-option"
                name={"Department"}
                onChange={(e) => {
                  setSelectedDepartment(e.target.value);
                }}
              >
                <option value={""}>{"Select Department"}</option>
                {departments?.map((item, index) => (
                  <option key={index} value={item?._id}>
                    {item?.department}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <TextInput
            name="Disease"
            formName={"disease"}
            data={formData}
            handleInput={handleInput}
            setError={setError}
          />
        </div>
      )}
      <div className="flex w-full">
        <SearchInput
          placeholder={"Search Doctor"}
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
        />
      </div>
      {!is_re_visit && (filterdDoctors?.length > 0 ? (
        <div className="w-full min-h-[250px] overflow-y-auto">
          {filterdDoctors?.map((doctor, index) => (
            <DoctorData
              key={index}
              setErr={setError}
              data={doctor?._id === data?.doctor?._id ? data : null}
              doctor={doctor}
              setData={setData}
            />
          ))}
        </div>
      ) : (
        <div className="text-white">
          <div className="flex w-full h-full justify-center items-center font-semibold">
            {selectedHospital
              ? "No doctors found !"
              : "hospital not selected !"}
          </div>
        </div>
      ))}
      {is_re_visit && data?.doctor && (
        <div className="w-full min-h-[250px] overflow-y-auto">
          <DoctorData
            is_re_visit={is_re_visit}
            setErr={setError}
            doctor={data?.doctor}
            data={data}
            setData={setData}
          />
        </div>
      )}
      <div className="flex w-full gap-2">
        <span className="text-shadow-black text-white">
          Do you have medidocs health card :
        </span>
        <input
          type="checkbox"
          name="have_card"
          checked={haveCard}
          onChange={handleCardChange}
        />
      </div>
      {haveCard && (
        <HaveCard
          cardId={cardId}
          haveCard={haveCard}
          handleOfferClick={handleOfferClick}
          selectedOffer={selectedOffer}
        />
      )}
      {haveCard && offerType && cardId && userId && !applyReponse && (
        <div className="flex w-fit h-fit">
          <button
            className="w-fit h-fit border bg-primary px-2 py-1 rounded-md  text-white border-primary text-shadow-black flex justify-center  items-center "
            disabled={applyOfferLoading}
            onClick={(e) => {
              handleOfferApply(e);
            }}
          >
            {applyOfferLoading ? "Please Wait" : "Apply"}
          </button>
        </div>
      )}
      {haveCard &&
        offerType &&
        cardId &&
        userId &&
        applyReponse &&
        !otpResponse && (
          <div className="flex w-full h-fit justify-between gap-5 items-center">
            <div className="lable-and-filed">
              <label
                htmlFor={"OTP Verification"}
                className={`text-[14px] text-shadow-black text-white`}
              >
                {"OTP Verification"} <span className="text-red-600">*</span>
              </label>
              <div
                className="input-coantiner"
                onClick={() => setError({ offer: "" })}
              >
                <input
                  type="number"
                  max={4}
                  name={"OTP Verification"}
                  value={otp}
                  onChange={(e) => {
                    setOtp(e.target.value);
                  }}
                  placeholder={"OTP Verification"}
                  className="input"
                />
              </div>
            </div>
            <button
              className="w-full h-fit mt-5 border bg-primary py-1.5 rounded-md  text-white border-primary text-shadow-black flex justify-center  items-center "
              disabled={otpLoading}
              onClick={(e) => {
                handleOtpVerify(e);
              }}
            >
              {otpLoading ? "Please Wait" : "Verfiy OTP"}
            </button>
          </div>
        )}
      {data?.doctor && (
        <div className="flex w-full h-fit items-center gap-1">
          <span className="text-white">Consultancy Fee - </span>
          <span
            className={`${
              !data?.doctor?.price ? "text-red-500" : "text-white"
            } flex items-center gap-0.5`}
          >
            {reducedPrice !== null ? (
              <>
                <FaRupeeSign fontSize={12} />
                <span className="flex gap-2">
                  <span>{reducedPrice.toFixed(2)}</span>
                  <span className="line-through">({data?.doctor?.price})</span>
                </span>
              </>
            ) : data?.doctor?.price ? (
              <>
                <FaRupeeSign fontSize={12} />
                {data?.doctor?.price}
              </>
            ) : (
              "Doctor Not Added Yet!"
            )}
          </span>
        </div>
      )}
      <div className="flex w-full h-fit justify-between gap-5">
        <SelectInput
          name="Payment Method"
          formName={"payment_method"}
          items={["Select Method", "Cash", "Online/UPI"]}
          data={formData}
          handleInput={handleInput}
          setError={setError}
        />
        <SelectInput
          name="Status"
          formName={"payment_status"}
          items={["Select Status", "Pending", "Paid"]}
          data={formData}
          handleInput={handleInput}
          setError={setError}
        />
      </div>
      {err.global && (
        <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
          {err.global}
        </div>
      )}
      <div className="flex w-full h-fit justify-between gap-5 flex-auto items-end">
        <button
          className="w-full h-fit border border-primary py-1 rounded-md  bg-white text-primary text-shadow-black flex justify-center items-center"
          onClick={() => {
            clearForm();
            setOpen(false);
          }}
        >
          Cancel
        </button>
        <button
          className="w-full h-fit border bg-primary py-1 rounded-md  text-white border-primary text-shadow-black flex justify-center  items-center "
          disabled={loading}
          onClick={(e) => {
            handleSubmit(e);
          }}
        >
          Submit
        </button>
      </div>
    </>
  );
};

export default ConsultationAppointmentForm;
