import { axiosInstance } from "../../api/config";

export const getServiceAppointments = async () => {
  try {
    const response = await axiosInstance.get("/servicepage/get_servicepage");
    return response;
  } catch (error) {
    throw error;
  }
}

export const deleteServiceAppointment = async (id) => {
  try {
    const response = await axiosInstance.delete(`/servicepage/delete_servicepage${id}`);
    return response;
  } catch (error) {
    throw error;
  }
}

