import React from "react";

const HospitalData = ({ data, setData, hospital }) => {
  const { _id, hospital_name, hospital_id, profile_image } = hospital;
  return (
    <div
      className={`flex w-full h-fit rounded-md border p-2 gap-3 mb-2 cursor-pointer ${
        _id === (data?._id ?? "") ? "bg-slate-100" : "bg-white"
      }`}
      onClick={() => setData(hospital)}
    >
      <img
        src={profile_image}
        className="w-[130px] min-w-[130px] max-w-[130px]  border shadow-md max-h-[130px] rounded-md object-contain flex-shrink-0"
        alt="profile_image"
      />
      <div className="flex flex-col gap-2 w-full">
        <span className="text-base">{hospital_name}</span>
        <span className="text-sm">{hospital_id}</span>
      </div>
    </div>
  );
};

export default HospitalData;
