import { Layout } from './layout';
import { Route, Routes } from 'react-router-dom';
import { Appointments, LabAppointments, Leads, Login, ServicesAppointments } from './pages';
import { AuthProtected, UserProtected } from './components/ProtectedRoutes/UserProtected';

function App() {
  return (
    <Routes>
      <Route path="/" element={<UserProtected element={<Layout />} />}>
        <Route index={true} element={<Appointments />} />
        <Route path="/leads" element={<Leads />} />
        <Route path="/lab-appointments" element={<LabAppointments />} />
        <Route path="/services-appointments" element={<ServicesAppointments />} />
      </Route>
      <Route path="/login" element={<AuthProtected element={<Login />} />} />
    </Routes>
  );
}

export default App;
