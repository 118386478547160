import React, { useCallback, useEffect, useRef, useState } from 'react'
import {  ImageInput, NumberInput, SelectInput, ShowImage, TextAreaInput, TextInput } from "../../components/Common/FormComponents";
import { useForm } from "../../hooks";
import { IoMdAdd } from "react-icons/io";
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { addAboutUs, getAboutUs } from '../../utils/aboutUtils/aboutUtils';
import { toast } from "react-toastify";
import { useAuth } from "../../context/useAuth";import JoditEditor, {Jodit} from "jodit-react";

const config = { readonly: false, placeholder: "Timings",
  uploader: {
    insertImageAsBase64URI: true,
  },
  controls: {
    paragraph: {
      list: Jodit.atom({
        p: "Pharagraph",
        h1: "Heading 1",
        h2: "Heading 2",
        h3: "Heading 3",
        h4: "Heading 4",
        h5: "Heading 5",
        h6: "Heading 6",
        blockquote: "Quote",
        div: "Div",
        pre: "Source code",
      }),
    },
  }, };
const AboutUsMainSection = () => {
    const user = useAuth().authState.user;
    const [editData, setEditData] = useState(null);
    const initialState = {
      hospital_name: editData?.hospital_name || "",
      hospital_location: editData?.hospital_location || "",
      description: editData?.description || "",
      about: editData?.about || "",
      address: editData?.address || "",
      payment_mode: editData?.payment_mode || "",
      no_of_beds: editData?.no_of_beds || 0,
      no_of_doctors: editData?.no_of_doctors || 0,
      review_point: editData?.review_point || 0,
      type_of_hospital: editData?.type_of_hospital || "",
      wheel_chair_availability:
        editData?.wheel_chair_availability || "Select Wheel Chair Availability",
      amenties: editData?.amenties || "",
      established_year: editData?.established_year || "",
      info: editData?.info || "",
      links: editData?.links || "",
  };
    const editor = useRef(null);
    const [timings, setTimings] = useState(editData?.timings || "<p></p>");
    const [err, setError] = useState({ global: "", speciality: "", procedure: "" });
    const [hospital_image, setHospitalImage] = useState(editData?.hospital_image || null);
    const [hospital_logo, setHospitalLogo] = useState(editData?.hospital_logo || null);
    const [specialities, setSpecialities] = useState(editData?.specialities || []);
    const [speciality, handleSpecialityInput, clearSpecialitesFeild] = useForm({ speciality: "" });
    const handleSpecialities = (action, idx = 0) => {
      if (action === "add") {
        if (!speciality.speciality.trim().length) {
          return setError({ speciality: "Please Enter A Speciality" });
        } else {
          setSpecialities([...specialities, speciality.speciality]);
          clearSpecialitesFeild();
        }
      } else if (action === "remove") {
        setSpecialities(specialities.filter((_, index) => index !== idx));
      }
    };
    const [procedures, setProcedures] = useState(editData?.procedure || []);
    const [procedure, handleProcedureInput, clearProcedureInput] = useForm({ procedure: "" });
    const handleProcedures = (action, idx = 0) => {
      if (action === "add") {
        if (!procedure.procedure.trim().length) {
          return setError({ procedure: "Please Enter A Procedure" });
        } else {
          setProcedures([...procedures, procedure.procedure]);
          clearProcedureInput();
        }
      } else if (action === "remove") {
        setProcedures(procedures.filter((_, index) => index !== idx));
      }
    };
  const [formData, handleInput, clearForm, setValues] = useForm(initialState);
    const setEditDataFn = useCallback(() => {
        getAboutUs(user?._id).then(res => {
            const fetchedEditData = res.data.data[0] || null;
            if(fetchedEditData){
              setValues({
                hospital_name: fetchedEditData?.hospital_name || "",
                hospital_location: fetchedEditData?.hospital_location || "",
                description: fetchedEditData?.description || "",
                about: fetchedEditData?.about || "",
                address: fetchedEditData?.address || "",
                timings: fetchedEditData?.timings || "",
                payment_mode: fetchedEditData?.payment_mode || "",
                wheel_chair_availability:
                  fetchedEditData?.wheel_chair_availability ||
                  "Select Wheel Chair Availability",
                amenties: fetchedEditData?.amenties || "",
                established_year: fetchedEditData?.established_year || "",
                info: fetchedEditData?.info || "",
                links: fetchedEditData?.links || "",
                no_of_beds: fetchedEditData?.no_of_beds || 0,
                no_of_doctors: fetchedEditData?.no_of_doctors || 0,
                review_point: fetchedEditData?.review_point || 0,
                type_of_hospital: fetchedEditData?.type_of_hospital || "",
              });
              setTimings(fetchedEditData?.timings || "<p></p>");
              setHospitalImage(fetchedEditData?.hospital_image || null);
              setHospitalLogo(fetchedEditData?.hospital_logo || null);
              setSpecialities(fetchedEditData?.specialities || []);
              setProcedures(fetchedEditData?.procedure || []);
              setEditData(res.data.data[0] || null);
            }
        }).catch(err => console.log(err)); 
    },[setValues, user?._id])
    useEffect(() => {
        if (!editData) {
            setEditDataFn()
        }
    }, [editData, setEditDataFn]);
    const [loading, setLoading] = useState(false);
    const handleSubmit = (e) => {
        e.preventDefault();
        if (formData.wheel_chair_availability === "Select Wheel Chair Availability") return setError({ global: "Please Select Wheel Chair Availability" });
        if (formData.review_point > 5) return setError({ global: "Maximum Review Point Is 5" });
        if (!hospital_image) return setError({ global: "Please Selecte Hospital Image" });
        if (!hospital_logo) return setError({ global: "Please Selecte Hospital Logo" });
        setError({ global: "" });
        setLoading(true)
        addAboutUs(formData,timings, specialities, procedures, hospital_image, hospital_logo, user?._id, editData?._id)
          .then(res => {
            setEditData(res.data.data[0]); 
            toast(`Hospital About Updated`, {
              type: "success",
              autoClose: 3000,
            });
          })
          .catch(err => {
            console.log(err); 
            toast(`Hospital About Update Failed`, {
              type: "error",
              autoClose: 3000,
            });
          })
            .finally(() => setLoading(false));
    }
  return (
    <div
      className="flex max-w-screen-lg w-full max-h-screen overflow-y-auto border h-full gap-5 p-5 m-auto text-white rounded-md flex-col"
      style={{
        background: "linear-gradient(180deg, #010D1E 0%, #1A59AE 100%)",
      }}
    >
      <div className="flex w-full h-fit items-center justify-between">
        <div className="flex justify-center items-center  gap-3 font-semibold">
          About Us
        </div>
      </div>
      <div className="flex w-full h-fit justify-between gap-5">
        <TextInput
          name="Hospital Name"
          formName={"hospital_name"}
          data={formData}
          handleInput={handleInput}
          setError={setError}
        />
        <TextInput
          name="Hospital Location"
          formName={"hospital_location"}
          data={formData}
          handleInput={handleInput}
          setError={setError}
        />
      </div>
      <div className="flex w-full h-fit justify-between gap-5">
        <TextAreaInput
          name="Description"
          notShow={true}
          formName={"description"}
          data={formData}
          handleInput={handleInput}
          setError={setError}
        />
      </div>
      <div className="flex w-full h-fit justify-between gap-5">
        <TextInput
          hide={true}
          name="Type Of Hospital"
          formName={"type_of_hospital"}
          data={formData}
          handleInput={handleInput}
          setError={setError}
        />
      </div>
      <div className="flex flex-col md:flex-row w-full h-fit justify-between gap-5">
        <SelectInput
          name="Wheel Chair Availability"
          formName={"wheel_chair_availability"}
          data={formData}
          handleInput={handleInput}
          items={[
            "Select Wheel Chair Availability",
            "Available",
            "Not Available",
          ]}
          setError={setError}
        />
        <TextInput
          name="Established Year"
          hide={true}
          formName={"established_year"}
          data={formData}
          handleInput={handleInput}
          setError={setError}
        />
      </div>
      <div className="flex flex-col md:flex-row w-full h-fit gap-5">
        <NumberInput
          min={0}
          name="No. Of Beds"
          formName={"no_of_beds"}
          data={formData}
          handleInput={handleInput}
          setError={setError}
        />
        <NumberInput
          min={0}
          name="No. Of Doctors"
          formName={"no_of_doctors"}
          data={formData}
          handleInput={handleInput}
          setError={setError}
        />
        <NumberInput
          min={0}
          max={5}
          name="Count Of Review Point"
          formName={"review_point"}
          data={formData}
          handleInput={handleInput}
          setError={setError}
        />
      </div>
      <div className="flex w-full h-fit justify-between gap-5">
        <ImageInput
          image={hospital_image}
          setImage={setHospitalImage}
          imageName={"Hospital Image"}
          dimension="Width:500px, Height:400px"
        />
        <ImageInput
          image={hospital_logo}
          setImage={setHospitalLogo}
          imageName={"Hospital Logo"}
          dimension="Width:80px, Height:80px"
        />
      </div>
      <div className="flex w-full h-fit justify-between gap-5">
        {
          <>
            <ShowImage image={hospital_image} setImage={setHospitalImage} />
            <ShowImage image={hospital_logo} setImage={setHospitalLogo} />
          </>
        }
      </div>
      <div className="flex w-full gap-1 flex-wrap">
        {specialities?.map((item, idx) => (
          <div
            className="flex gap-2 items-center border  rounded-md p-1 shadow-md text-xs"
            key={idx}
          >
            <span>{item}</span>
            <button
              onClick={() => {
                handleSpecialities("remove", idx);
              }}
            >
              <AiOutlineCloseCircle fontSize={14} color="red" />
            </button>
          </div>
        ))}
      </div>
      <div className="flex w-full sm:w-1/2 h-fit justify-between gap-5 ">
        <TextInput
          name="Specialities"
          formName={"speciality"}
          data={speciality}
          handleInput={handleSpecialityInput}
          setError={setError}
        />
        <button
          onClick={() => {
            handleSpecialities("add");
          }}
          className="flex justify-center mt-5  items-center border-none outline-none"
        >
          <IoMdAdd fontSize={28} />
        </button>
      </div>
      {err.speciality && (
        <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
          {err.speciality}
        </div>
      )}
      <div className="flex w-full gap-1 flex-wrap">
        {procedures?.map((item, idx) => (
          <div
            className="flex gap-2 items-center border  rounded-md p-1 shadow-md text-xs"
            key={idx}
          >
            <span>{item}</span>
            <button
              onClick={() => {
                handleProcedures("remove", idx);
              }}
            >
              <AiOutlineCloseCircle fontSize={14} color="red" />
            </button>
          </div>
        ))}
      </div>
      <div className="flex w-full sm:w-1/2 h-fit justify-between gap-5 ">
        <TextInput
          name="Procedure"
          formName={"procedure"}
          data={procedure}
          handleInput={handleProcedureInput}
          setError={setError}
        />
        <button
          onClick={() => handleProcedures("add")}
          className="flex justify-center mt-5  items-center border-none outline-none"
        >
          <IoMdAdd fontSize={28} />
        </button>
      </div>
      {err.procedure && (
        <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
          {err.procedure}
        </div>
      )}
      <div className="flex w-full h-fit justify-between gap-5">
        <TextAreaInput
          name="About"
          notShow={true}
          formName={"about"}
          data={formData}
          handleInput={handleInput}
          setError={setError}
        />
      </div>
      <div className="flex w-full h-fit justify-between gap-5">
        <TextAreaInput
          name="Address"
          notShow={true}
          formName={"address"}
          data={formData}
          handleInput={handleInput}
          setError={setError}
        />
      </div>
      <div className="flex w-full h-fit justify-between gap-5">
        <div className="flex flex-col w-full gap-0.5 text-black">
          <label htmlFor={"Timings"} className="label">
            {"Timings"} <span className="text-red-600">*</span>
          </label>
          <JoditEditor
            ref={editor}
            value={timings}
            config={config}
            tabIndex={1}
            onBlur={(value) => setTimings(value)}
          />
        </div>
      </div>
      <div className="flex w-full h-fit justify-between gap-5">
        <TextAreaInput
          name="Mode Of Payment"
          notShow={true}
          formName={"payment_mode"}
          data={formData}
          handleInput={handleInput}
          setError={setError}
        />
        <TextAreaInput
          name="Amenities"
          notShow={true}
          formName={"amenties"}
          data={formData}
          handleInput={handleInput}
          setError={setError}
        />
      </div>
      <div className="flex w-full font-semibold">Testominals</div>
      <div className="flex w-full h-fit justify-between gap-5">
        <TextInput
          name="Info"
          notShow={true}
          formName={"info"}
          data={formData}
          handleInput={handleInput}
          setError={setError}
        />
        <TextInput
          name="Links"
          notShow={true}
          formName={"links"}
          data={formData}
          handleInput={handleInput}
          setError={setError}
        />
      </div>
      {err.global && (
        <div className="flex text-sm text-red-600 w-full h-fit justify-center items-center font-semibold">
          {err.global}
        </div>
      )}
      <div className="flex w-full h-fit justify-between gap-5">
        <div className=" flex flex-col w-full gap-0.5 ">
          <button
            className="w-full border border-primary py-1 rounded-md bg-white text-primary text-shadow-black flex justify-center items-center"
            onClick={() => {
              clearForm();
              setEditData(null);
            }}
          >
            Cancel
          </button>
        </div>
        <div className=" flex flex-col w-full gap-0.5 ">
          <button
            className="w-full border bg-primary py-1 rounded-md text-white border-primary text-shadow-black flex justify-center items-center"
            disabled={loading}
            onClick={(e) => {
              handleSubmit(e);
            }}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}

export default AboutUsMainSection