import { axiosInstance } from "../../api/config";

export const addRegister = async (initialState, appointmentId = null) => {
  try {
    const requestData = {
      ...initialState,
      password: "12345678"
    };
    const requestConfig = {
      headers: { "Content-Type": "application/json" },
    };
    const url = `/register/create_register`;
    const response = await axiosInstance['post'](url, requestData, requestConfig);
    return response;
  } catch (error) {
    throw error;
  }
}
export const verifyRegisterWithOtp = async (registerId, email, otpVerify, setErr) => {
  try {
    const requestData = {
      email,
      otpVerify
    };
    const requestConfig = {
      headers: { "Content-Type": "application/json" },
    };
    const url = `/register/otp_verify/${registerId}`
    const response = await axiosInstance['post'](url, requestData, requestConfig);
    return response;
  } catch (error) {
    setErr({ global: "Otp Not Matching" })
    throw error;
  }
}